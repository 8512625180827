.print_show {
  display: inherit;
}

.print_hide {
  display: none;
}

html[data-theme] {
  background-color: #FFF;
}

body {
  background-color: #FFF;
  color: #222;
  font-size: calc(0.65625 * 1em);
  margin: auto;
}
body hr {
  margin: 20px 50px;
}
body header.loaded::before,
body article.loaded::before {
  font-size: calc(0.65625 * 20px);
}
body article:nth-of-type(2) span {
  display: inline-block;
  line-height: 1;
  padding: 8px;
}
body article > [data-template].loaded[data-template=contact] {
  flex-direction: row;
}
body article > [data-template=career] li {
  margin: 10px 0;
}
body a:link:not(.print_no_href):after,
body a:visited:not(.print_no_href):after {
  content: " (" attr(href) ") ";
  font-size: 80%;
}
body a:not([href^=http]):not(.print_no_href):after {
  content: " (https://remirobichet.fr/" attr(href) ") ";
}

@page {
  size: A4;
  margin: 20mm 16mm 20mm 16mm;
}
/*# sourceMappingURL=index.42e4a8a7.css.map */
