//colors
$color_background: #FFF;
$color_text: #222;

$print_font_size: 10.5/16;

.print_show {
    display: inherit;
}

.print_hide {
    display: none;
}

html[data-theme] {
    background-color: $color_background;
}

body {
    background-color: $color_background;
    color: $color_text;
    font-size: calc(#{$print_font_size} * 1em);
    margin: auto;

    hr {
        margin: 20px 50px;
    }

    header,
    article {
        
        &.loaded {
            &::before {
                font-size: calc(#{$print_font_size} * 20px);
            }
        }
    }

    article:nth-of-type(2) {
        span {
            display: inline-block;
            line-height: 1;
            padding: 8px;
        }
    }

    article > [data-template].loaded[data-template="contact"] {
        flex-direction: row;
    }

    article > [data-template="career"] {
        li {
            margin: 10px 0;
        }
    }

    a:link:not(.print_no_href):after,
    a:visited:not(.print_no_href):after {
        content: " ("attr(href) ") ";
        font-size: 80%;
    }

    a:not([href^="http"]):not(.print_no_href):after {
        content: " (https://remirobichet.fr/"attr(href) ") ";
    }
}

@page {
    size: A4;
    margin: 20mm 16mm 20mm 16mm; 
}
